<template>
  <div class="has-padding-bottom">
    <div class="has-margin-bottom-x2">
      <ui-base-input-field
        :label="$t('Components.Companies.CreateCompany.Label_CompanyName')"
        v-model.trim="company.Name"
        :error="nameErrors.toString()"
        @input="$v.company.Name.$touch()"
        @blur="$v.company.Name.$touch()"
        required
      />
      <ui-base-input-field
        :label="$t('Components.Companies.CreateCompany.Label_Address')"
        v-model.trim="company.PostAddress"
        :error="postAddressErrors"
        @input="$v.company.PostAddress.$touch()"
        @blur="$v.company.PostAddress.$touch()"
        required
      />
      <ui-base-input-field
        :label="$t('Components.Companies.CreateCompany.Label_PostalCode')"
        v-model.trim="company.PostPostalCode"
        :error="postPostalCodeErrors"
        @input="$v.company.PostPostalCode.$touch()"
        @blur="$v.company.PostPostalCode.$touch()"
        required
      />
      <ui-base-input-field
        :label="$t('Components.Companies.CreateCompany.Label_City')"
        v-model.trim="company.PostCity"
        :error="postCityErrors"
        @input="$v.company.PostCity.$touch()"
        @blur="$v.company.PostCity.$touch()"
        required
      />
      <BaseFieldTemplate
        :required="false"
        :label="$t('Components.Companies.CreateCompany.Label_Country')"
      >
        <template v-slot:fields>
          <div class="select">
            <select v-model="company.PostCountry">
              <option
                :value="0"
                :selected="Number(company.PostCountry) === 0"
              >{{$t('Components.Companies.CreateCompany.Option_ChooseCountry')}}</option>
              <option
                v-for="(country, key) in $t('CountryLabels')"
                :key="key"
                :selected="key === 'Country' + company.PostCountry"
                :value="Number(key.split('Country')[1])"
              >
                {{ country }}
              </option>
            </select>
          </div>
        </template>
      </BaseFieldTemplate>
      <!-- <ui-base-input-field
        :label="$t('Components.Companies.CreateCompany.Label_Country')"
        v-model.trim="company.PostCountry"
        :error="postCountryErrors"
        @input="$v.company.PostCountry.$touch()"
        @blur="$v.company.PostCountry.$touch()"
        required
      /> -->
      <ui-base-input-field
        :label="$t('Components.Companies.CreateCompany.Label_PhoneNumber')"
        v-model.trim="company.Phone"
      />
    </div>
    <div class="has-margin-bottom">
      <div
        class="title is-5"
        v-text="$t('Components.Companies.CreateCompany.Header_SendToAddress')"
      ></div>
      <ui-base-input-field
        :label="$t('Components.Companies.CreateCompany.Label_SendTo')"
        v-model.trim="company.SendInvoiceTo"
        :error="sendInvoiceToErrors"
        @input="$v.company.SendInvoiceTo.$touch()"
        @blur="$v.company.SendInvoiceTo.$touch()"
        required
      />
      <ui-base-input-field
        :label="$t('Components.Companies.CreateCompany.Label_InvoiceEmail')"
        v-model.trim="company.EmailInvoice"
        :error="emailInvoiceErrors"
        @input="$v.company.EmailInvoice.$touch()"
        @blur="$v.company.EmailInvoice.$touch()"
        required
      />
    </div>

    <Message
      class="is-danger has-margin-bottom"
      :message="errorMessage"
      v-if="errorMessage"
    />

    <div class="level">
      <div class="level-left"></div>
      <div class="level-right">
        <button
          class="button is-success"
          @click="save"
          :class="{ 'is-loading': isSaving }"
          :disabled="Boolean($v.$error)"
          v-html="$t('Form.Control.Save')"
        ></button>
      </div>
    </div>
  </div>
</template>

<script>
const Message = () => import('@/components/UI/Message')
const BaseFieldTemplate = () => import('@/components/UI/Form/BaseFieldTemplate')
import { required, email } from 'vuelidate/lib/validators'
import companyProvider from '../../providers/company'
const touchMap = new WeakMap()

export default {
  name: 'createCompany',

  props: {
    locationId: {
      type: Number,
      required: true
    }
  },

  components: {
    BaseFieldTemplate,
    Message
  },

  data() {
    return {
      errorMessage: '',
      isSaving: false,
      company: {
        LocationId: 0,
        Name: '',
        PostAddress: '',
        PostPostalCode: '',
        PostCity: '',
        PostCountry: 0,
        Phone: '',
        SendInvoiceTo: '',
        EmailInvoice: '',
        Address: '',
        City: '',
        PostalCode: '',
        Country: 0,
        Email: '',
      }
    }
  },

  /**
   * Form validation rules
   */
  validations() {
    let output = {
      Name: {
        required
      },
      PostAddress: {
        required
      },
      PostPostalCode: {
        required
      },
      PostCity: {
        required
      },
      // PostCountry: {
      //   required
      // },
      SendInvoiceTo: {
        required
      },
      EmailInvoice: {
        required,
        email,
      },
    }
    return { company: output }
  },

  computed: {
    // Error messages
    nameErrors() {
      const errors = []
      if (!this.$v.company.Name.$dirty) { return errors }
      if (!this.$v.company.Name.required) {
        errors.push(this.$t('Form.InputErrors.Required'))
      }
      return errors
    },

    postAddressErrors() {
      const errors = []
      if (!this.$v.company.PostAddress.$dirty) { return errors }
      if (!this.$v.company.PostAddress.required) {
        errors.push(this.$t('Form.InputErrors.Required'))
      }
      return errors
    },

    postPostalCodeErrors() {
      const errors = []
      if (!this.$v.company.PostPostalCode.$dirty) { return errors }
      if (!this.$v.company.PostPostalCode.required) {
        errors.push(this.$t('Form.InputErrors.Required'))
      }
      return errors
    },

    postCityErrors() {
      const errors = []
      if (!this.$v.company.PostCity.$dirty) { return errors }
      if (!this.$v.company.PostCity.required) {
        errors.push(this.$t('Form.InputErrors.Required'))
      }
      return errors
    },

    postCountryErrors() {
      const errors = []
      if (!this.$v.company.PostCountry.$dirty) { return errors }
      if (!this.$v.company.PostCountry.largerThen) {
        errors.push(this.$t('Form.InputErrors.Required'))
      }
      return errors
    },

    sendInvoiceToErrors() {
      const errors = []
      if (!this.$v.company.SendInvoiceTo.$dirty) { return errors }
      if (!this.$v.company.SendInvoiceTo.required) {
        errors.push(this.$t('Form.InputErrors.Required'))
      }
      return errors
    },

    emailInvoiceErrors() {
      const errors = []
      if (!this.$v.company.EmailInvoice.$dirty) { return errors }
      if (!this.$v.company.EmailInvoice.required) {
        errors.push(this.$t('Form.InputErrors.Required'))
      } else if (!this.$v.company.EmailInvoice.email) {
        errors.push(this.$t('Form.InputErrors.InvalidEmail'))
      }
      return errors
    }
  },

  created() {
    this.company.LocationId = this.locationId
  },

  methods: {
    save() {
      let self = this
      this.errorMessage = ''
      if (!this.isSaving) {
        this.isSaving = true
        this.$v.$touch()
        if (this.$v.$invalid) {
          this.isSaving = false
          self.errorMessage = self.$t('Components.Companies.CreateCompany.Message_NotAllFilledIn')
        } else {
          this.company.Address = this.company.PostAddress
          this.company.City = this.company.PostCity
          this.company.PostalCode = this.company.PostPostalCode
          this.company.Country = this.company.PostCountry
          this.company.Email = this.company.EmailInvoice

          companyProvider.methods.saveCompany(this.company)
            .then(response => {
              self.$emit('nextAction', response.data)
            })
            .catch(() => {
              self.isSaving = false
              self.errorMessage = self.$t('Components.Companies.CreateCompany.Message_Error500')
            })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>