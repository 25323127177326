<template>
  <div>
    <div class="field has-addons">
      <search-bar
        :searchTerm="mSearchTerm"
        :isSearching="isSearching"
        @startSearch="startSearchProfiles"
      ></search-bar>
    </div>

    <div v-for="(profile, index) in profiles" :key="index">
      <article class="media has-margin-bottom">
        <figure class="media-left">
          <p class="image is-32x32">
            <img src="https://bulma.io/images/placeholders/96x96.png" />
          </p>
        </figure>
        <div class="media-content">
          <div class="content">
            <div>
              <div>
                <span class="tag is-small is-success" v-if="profile.Enabled"
                  >Activated</span
                >
                <span class="tag is-small is-warning" v-if="!profile.Enabled"
                  >Not activated</span
                >
                <span class="has-text-weight-bold">{{ profile.FullName }}</span>
              </div>
              <div>{{ profile.Email }}</div>
            </div>
          </div>
        </div>
        <div class="media-right">
          <button
            @click="fireProfileClicked(profile)"
            class="button is-primary is-small"
          >
            Select
          </button>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
import profileProvider from "@/providers/profile";

const SearchBar = () => import("@/components/SearchBar");

export default {
  components: {
    "search-bar": SearchBar
  },

  props: {
    locationId: {
      default: 0,
      type: Number
    },
    searchTerm: {
      default: "",
      type: String
    }
  },

  data() {
    return {
      isSearching: false,
      itemsPerPage: 25,
      page: 1,
      profiles: [],
      mSearchTerm: this.searchTerm
    };
  },

  beforeDestroy() {
    this.profiles = [];
  },

  methods: {
    startSearchProfiles(searchTerm) {
      this.mSearchTerm = searchTerm;
      this.isSearching = true;

      profileProvider.methods
        .getProfiles(
          this.mSearchTerm,
          this.page,
          this.itemsPerPage,
          this.locationId
        )
        .then(response => {
          this.profiles = response.data.Results;
        })
        .finally(() => {
          this.isSearching = false;
        });
    },

    fireProfileClicked(profile) {
      this.profiles = [];
      this.mSearchTerm = "";
      this.$emit("profileSelected", profile);
    }
  }
};
</script>
