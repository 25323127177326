<template>
  <div>
    <div class="field has-addons">
      <search-bar
        :searchTerm="mSearchTerm"
        :isSearching="isSearching"
        @startSearch="startSearchCompanies"
      ></search-bar>
    </div>

    <div v-for="company in companies" :key="company.Id">
      <article class="media has-margin-bottom">
        <div class="media-content">
          <div class="content">
            <div>
              <div>
                <span class="has-text-weight-bold">{{ company.Name }}</span>
              </div>
              <div>{{ company.PostAddress }}</div>
              <div>{{ company.PostPostalCode }} {{ company.PostCity }}</div>
              <div>
                {{ company.SendInvoiceTo }} - {{ company.EmailInvoice }}
              </div>
            </div>
          </div>
        </div>
        <div class="media-right">
          <button
            @click="selectCompany(company)"
            class="button is-primary is-small"
          >
            Select
          </button>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
import companyProvider from '@/providers/company'

const SearchBar = () => import('@/components/SearchBar')

export default {
  components: {
    'search-bar': SearchBar,
  },

  props: {
    channelAuthorizeOnly: {
      default: false,
      type: Boolean,
    },
    locationId: {
      default: 0,
      type: Number,
    },
    searchTerm: {
      default: '',
      type: String,
    },
  },

  data() {
    return {
      companies: [],
      isSearching: false,
      itemsPerPage: 25,
      page: 1,
      mSearchTerm: this.searchTerm,
      channelId: Number(this.$route.params.channelId),
    }
  },

  beforeDestroy() {
    this.companies = []
  },

  methods: {
    startSearchCompanies(searchTerm) {
      this.mSearchTerm = searchTerm
      this.searchCompanies()
    },

    searchCompanies() {
      this.isSearching = true

      companyProvider.methods
        .getCompanies(
          this.mSearchTerm,
          this.page,
          this.itemsPerPage,
          this.channelId,
          this.channelAuthorizeOnly
        )
        .then((response) => {
          this.companies = response.data.Results
        })
        .finally(() => {
          this.isSearching = false
        })
    },

    selectCompany(company) {
      this.companies = []
      this.mSearchTerm = ''
      this.$emit('companySelected', company)
    },
  },
}
</script>
